import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../resources/routes-constants'

const HomePage: React.FC = () => {
    const navigate = useNavigate()

    const redirectToLocalAuthors = () => {
        navigate(ROUTES.LOCAL_AUTHORS_ROUTE)
    }

    return (
        <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <span style={{ cursor: 'pointer', fontSize: '4em' }} onClick={() => redirectToLocalAuthors()}>
                Local Authors
            </span>
        </div>
    )
}

export default HomePage
